import BuilderSteps from '@/views/Projects/Project/Components/BuilderSteps'
import DynamicDropdownsList from './DynamicDropdownsList'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import FixedBottomPanel from '@/views/Projects/Project/Components/FixedBottomPanel'
import EmptyOptionsList from './EmptyOptionsList'

import {isEqual} from 'lodash/lang'

export default {
    components: {
        BuilderSteps,
        DynamicDropdownsList,
        ConfirmDialog,
        FixedBottomPanel,
        EmptyOptionsList,
    },
    data() {
        return {
            loadingPage: false,
            loading: false,
            origin: {},
        }
    },
    computed: {
        isEditForm() {
            return !isEqual(this.form, this.origin)
        },
    },
    inject: ['toast'],
    async mounted() {
        this.loadingPage = true

        this.fetchData &&
        (await this.fetchData().finally(() => {
            this.loadingPage = false
        }))
    },
    methods: {
        fetchUsersForWorkflowFields(excludedIds, key) {
            this[`disabledFields${key}`] = true
            this.loading = true

            const dictionary = {
                Drafting: 'scope_editing',
                Approving: 'scope_approvals',
                Negotiating: 'document_approvals',
                Signing: 'signature_approvals',
            }

            this.$http.projects
                .getUsersForWorkflowFields({
                    params: {
                        project_id: this.$route.params.id,
                        section: dictionary[key],
                        excluded_ids: excludedIds,
                    },
                })
                .then(({data: {data}}) => {
                    this[`options${key}`] = data.map((user) => ({
                        value: user.id,
                        name: `${user.full_name} (${user.job_title})`,
                        positionName: user.job_title,
                    }))
                })
                .finally(() => {
                    this.loading = false
                    this[`disabledFields${key}`] = false
                })
        },
        setOptionsWithExcludedIds(fields, key) {
            const excludedIds =
                fields
                    ?.filter((el) => el.value && el.value)
                    .map((el) => el.value)
                    .join(',') || ''
            this.fetchUsersForWorkflowFields(excludedIds, key)
        },

        dataAdapterWorkflowFields(data) {
            const getCurrentUser = (users, userId) =>
                users?.filter((user) => user.id === userId)[0]
            const nameWithJob = (name, job) => `${name} (${job})`

            const getUser = (users, userId) => {
                let value, name
                if (userId) {
                    const user = getCurrentUser(users, userId)
                    value = userId
                    name = nameWithJob(user?.full_name, user?.job_title)
                } else {
                    if (users.length && users.length === 1) {
                        const user = users[0]
                        value = user.id
                        name = nameWithJob(user?.full_name, user?.job_title)
                    } else {
                        value = null
                        name = null
                    }
                }

                return {
                    value,
                    name,
                }
            }

            return data?.map((el) => ({
                settingId: el.setting_id,
                positionName: el.position_name,
                mandatory: el.mandatory,
                ...getUser(el.users, el.user_id),
            }))
        },

        changePeopleWorkflow(items, key) {
            this.form[key] = items
        },

        deleteProjectHandler() {
            this.$refs.confirmDialog
                .confirm({
                    text: `Are you sure you want to delete project "${this.form.projectName}", delete all related scopes and revoke all related tasks and contract assignments?`,
                    cancelText: 'Cancel',
                    confirmText: 'Yes, delete',
                    reverse: true,
                })
                .then((response) => {
                    if (response) {
                        this.deleteProject()
                    }
                })
        },
        deleteProject() {
            this.loading = true
            this.$http.projects
                .deleteProject(this.$route.params.id)
                .then((response) => {
                    this.toast('info', response.data.message)
                    this.$router.push({name: 'ProjectsList'})
                })
                .catch(({response}) => {
                    this.toast('warning', response.data.message)
                })
                .finally(() => {
                    this.loading = false
                })
        },

        save({redirect = false, workflowKey = ''}) {
            this.loading = true
            const preparingData = (data) =>
                data
                    .filter((el) => el.value)
                    .map((el) => {
                        return {
                            setting_id: el.settingId,
                            user_id: el.value,
                        }
                    })

            const dictionary = {
                scope: {
                    firstSectionFields: 'fieldsDrafting',
                    secondSectionFields: 'fieldsApproving',
                    workflowId: 'scopeId',
                    workflowIdKeyName: 'scope_approval_workflow_id',
                    firstSectionKeyName: 'scope_editing',
                    secondSectionKeyName: 'scope_approvals',
                    saveRequestName: 'saveThirdStep',
                    routeParams: {
                        name: 'ProjectContractWorkflow',
                        params: {id: this.$route.params.id},
                    },
                },
                contract: {
                    firstSectionFields: 'fieldsNegotiating',
                    secondSectionFields: 'fieldsSigning',
                    workflowId: 'contractId',
                    workflowIdKeyName: 'contract_approval_workflow_id',
                    firstSectionKeyName: 'document_approvals',
                    secondSectionKeyName: 'signature_approvals',
                    saveRequestName: 'saveFourthStep',
                    routeParams: {
                        name: 'ProjectTemplateCollection',
                        params: {id: this.$route.params.id, create: false},
                    },
                },
            }

            const currentWorkflow = dictionary[workflowKey]

            const firstSectionFieldsData = preparingData(
                this.form[currentWorkflow.firstSectionFields],
            )
            const secondSectionFieldsData = preparingData(
                this.form[currentWorkflow.secondSectionFields],
            )
            this.$http.projects[currentWorkflow.saveRequestName](
                this.$route.params.id,
                {
                    workflow_id: this.form[currentWorkflow.workflowId],
                    project_name: this.form.projectName,
                    [currentWorkflow.firstSectionKeyName]: firstSectionFieldsData,
                    [currentWorkflow.secondSectionKeyName]: secondSectionFieldsData,
                },
            )
                .then(({data}) => {
                    this.origin[currentWorkflow.workflowId] =
                        data.data[currentWorkflow.workflowIdKeyName]

                    this.step = data.data.step

                    this.origin = this.$deepClone(this.form)

                    this.$store.dispatch('fetchProjectPermissions', this.$route.params.id)
                    this.toast('info', data.message)

                    if (redirect) this.$router.push(currentWorkflow.routeParams)
                })
                .catch((error) => {
                    this.toast('warning', error.response.data.message)
                })
                .finally(() => {
                    this.loading = false
                })
        },

        async handleCancel() {
            if (this.isEditForm) {
                await this.$refs.confirmDialog
                    .confirm({
                        text: this.$t('messages.unsaved'),
                        cancelText: 'Cancel',
                        confirmText: 'OK',
                        reverse: true,
                    })
                    .then((res) => {
                        if (res) {
                            this.cancel()
                        }
                    })
            } else {
                this.cancel()
            }
        },

        cancel() {
            this.form = this.$deepClone(this.origin)
            window.scrollTo(0, 0)
        },
    },
}
